import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { AxiosError, AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';

import { Address, LoqateFindResponse } from '^/address/types';
import { CONTACTS_ENDPOINT, getContact } from '^/contacts/actions';
import { throwSubmissionError } from '^/utils/action-helpers';
import { wrapThunkAction } from '^/utils/thunk-action-wrap';

export const ADDRESSES_ENDPOINT = '/api/addresses/';

const API_KEY = window.LOQATE_API_KEY;
export const findContainersByPostcode = (postcode: string) => {
  return `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=${API_KEY}&IsMiddleware=True&Text=${postcode}`;
};

export const findAddressesByContainerId = (id: string) => {
  return `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=${API_KEY}&IsMiddleware=True&Container=${id}`;
};

export const retrieveAddress = (id: string) => {
  return `https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws?Key=${API_KEY}&Id=${id}`;
};

export const LOOKUP_CONTAINER = makeAsyncActionSet('LOOKUP_CONTAINER');
export const LOOKUP_ADDRESS = makeAsyncActionSet('LOOKUP_ADDRESS');
export function lookUpAddress(postcode: string) {
  return (dispatch: Dispatch) => {
    return request(
      LOOKUP_CONTAINER,
      findContainersByPostcode(postcode),
      'POST'
    )(dispatch)
      .then((response: void | AxiosResponse<any>) => {
        const container: null | LoqateFindResponse =
          response &&
          response.data.Items.find(
            (element: LoqateFindResponse) => element.Type === 'Postcode'
          );
        return (
          container &&
          request(
            LOOKUP_ADDRESS,
            findAddressesByContainerId(container.Id),
            'POST'
          )(dispatch).catch(() => null)
        );
      })
      .catch(() => null);
  };
}

export const CLEAR_ADDRESSES = 'CLEAR_ADDRESSES';
export const clearAddresses = () => ({
  type: CLEAR_ADDRESSES,
});

export const SELECT_ADDRESS = makeAsyncActionSet('SELECT_ADDRESS');
export function selectAddress(address: LoqateFindResponse) {
  return (dispatch: Dispatch) => {
    return request(
      SELECT_ADDRESS,
      retrieveAddress(address.Id),
      'POST'
    )(dispatch).catch(() => null);
  };
}

export const shouldRethrow = (error: AxiosError): boolean =>
  error.response !== undefined &&
  400 <= error.response.status &&
  error.response.status <= 500;

export const thunkWrappedSaveAddress = wrapThunkAction(saveAddress);
export const SAVE_ADDRESS = makeAsyncActionSet('SAVE_ADDRESS');
export function saveAddress(addressId: string | null, address: Address) {
  return (dispatch: Dispatch) => {
    const saveAddressRequest = addressId
      ? request(
          SAVE_ADDRESS,
          `${ADDRESSES_ENDPOINT}${addressId}/`,
          'PATCH',
          address,
          { shouldRethrow }
        )
      : request(SAVE_ADDRESS, `${ADDRESSES_ENDPOINT}`, 'POST', address, {
          shouldRethrow,
        });

    return saveAddressRequest(dispatch)
      .then(() => {
        getContactAddresses(address.contact)(dispatch);
        getContact(address.contact)(dispatch);
      })
      .catch(throwSubmissionError);
  };
}

export const DELETE_ADDRESS = makeAsyncActionSet('DELETE_ADDRESS');
export function deleteAddress(addressId: string, personId: string) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_ADDRESS,
      `${ADDRESSES_ENDPOINT}${addressId}/`,
      'DELETE'
    )(dispatch)
      .then(() => {
        getContactAddresses(personId)(dispatch);
      })
      .catch(() => null);
  };
}

export const GET_CONTACT_ADDRESSES = makeAsyncActionSet(
  'GET_CONTACT_ADDRESSES'
);
export function getContactAddresses(contactId: string) {
  return (dispatch: Dispatch) => {
    request(
      GET_CONTACT_ADDRESSES,
      `${ADDRESSES_ENDPOINT}?contact=${contactId}&page_size=100`,
      'GET',
      undefined,
      { metaData: { contactId, page: 1, pageSize: 100 } }
    )(dispatch);
  };
}

export const SET_MAILING_ADDRESS = makeAsyncActionSet('SET_MAILING_ADDRESS');
export function setMailingAddress(
  contactId: string,
  addressId: string
): (dispatch: Dispatch<AnyAction>) => Promise<void | null> {
  return (dispatch: Dispatch) => {
    return request(
      SET_MAILING_ADDRESS,
      `${CONTACTS_ENDPOINT}/${contactId}/`,
      'PATCH',
      { mailing_address: addressId }
    )(dispatch)
      .then(() => {
        getContactAddresses(contactId)(dispatch);
      })
      .catch(() => null);
  };
}

export const addressActions = [
  DELETE_ADDRESS,
  GET_CONTACT_ADDRESSES,
  SAVE_ADDRESS,
];
